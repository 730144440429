<template>
  <section id="content">
    <base-card>
      <blockquote class="blockquote pr-6 pr-md-0">
        I'll add in some project details here one day. For now, check <a href="https://github.com/dtdas">Github</a>.
      </blockquote>
    </base-card>
  </section>
</template>

<script>
  export default {
    name: 'ProjectsContents',
  }
</script>
